<template>
    <fragment>
        <li
            class="menu-item menu-item-submenu"
            :class="{ 'menu-item-active menu-item-active menu-item-open menu-item-here': active }"
            aria-haspopup="true"
            data-menu-toggle="click"
        >
            <a href="javascript:;" @click="toggleSidebar" class="menu-link menu-toggle" v-if="subitems">
                <span class="svg-icon menu-icon" v-html="icon"></span>
                <span class="menu-text">{{ label }}</span>
                <i class="menu-arrow"></i>
            </a >
            <fragment v-else>
                <a :href="to" class="menu-link" v-if="outside" target="_blank">
                    <span class="svg-icon menu-icon" v-html="icon"></span>
                    <span class="menu-text">{{ label }}</span>
                </a>
                <router-link :to="to" class="menu-link" v-else @click.native="toggleSidebarMobile">
                    <span class="svg-icon menu-icon" v-html="icon"></span>
                    <span class="menu-text">{{ label }}</span>
                </router-link>
            </fragment>
            <div class="menu-submenu" v-if="subitems">
                <i class="menu-arrow"></i>
                <ul class="menu-subnav">
                    <li
                        class="menu-item"
                        aria-haspopup="true"
                        v-for="(subItem, index) in subitems"
                        :key="index"
                        :class="{ 'menu-item-active': subMenu === subItem.label }"
                    >
                        <a :href="subItem.to" class="menu-link" v-if="subItem.outside">
                            <i class="menu-bullet menu-bullet-dot">
                                <span></span>
                            </i>
                            <span class="menu-text">{{ subItem.label }}</span>
                        </a>
                        <router-link :to="subItem.to" class="menu-link" v-else @click.native="toggleSidebarMobile">
                            <i class="menu-bullet menu-bullet-dot">
                                <span></span>
                            </i>
                            <span class="menu-text">{{ subItem.label }}</span>
                        </router-link>
                    </li>
                </ul>
            </div>
        </li>
    </fragment>
</template>

<script>
export default {
    props: ['icon', 'label', 'active', 'subitems', 'subMenu', 'to', 'outside'],
    methods: {
        toggleSidebar() {
            this.$store.commit('TOGGLE_SIDEBAR', false)
        },
        toggleSidebarMobile() {
            this.$store.commit("TOGGLE_SIDEBAR_MOBILE" , false)
        }
    },
}
</script>
