import Vue from 'vue'
import { Plugin } from 'vue-fragment'
// import VJsoneditor from 'v-jsoneditor'
import VueCookies from 'vue-cookies'
import Toast from 'vue-toastification'
// import VueClipboard from 'vue-clipboard2'
import 'vue-toastification/dist/index.css'
import "element-ui/lib/theme-chalk/index.css";
import ElementUI from "element-ui";
import App from './App.vue'
import router from './router'
import store from './store'
import locale from "element-ui/lib/locale/lang/en";
import vuetify from '@/plugins/vuetify'
import 'vuetify/dist/vuetify.min.css'

import "@/styles/index.scss"
import "@/utility/components-register"


const optionsToast = {
	timeout: 3000,
	closeOnClick: true,
	pauseOnHover: true,
}
Vue.use(ElementUI, { locale });
Vue.config.productionTip = false
Vue.use(Plugin)
// Vue.use(CKEditor)
// Vue.use(VJsoneditor)
// Vue.use(VueClipboard)
Vue.use(Toast, optionsToast)
Vue.use(VueCookies)

router.beforeEach((to, from, next) => {
	if (to.meta.middleware) {
		const middleware = Array.isArray(to.meta.middleware)
			? to.meta.middleware
			: [to.meta.middleware];

		const context = {
			from,
			next,
			router,
			to,
		};

		return middleware[0]({ ...context });
	}

	return next();
})


new Vue({
	router,
	store,
	vuetify,
	render: h => h(App)
}).$mount('#app')


// copy wtk
// if(window.location.hostname === "localhost" || window.location.port === "8080") {
// const userData = {
// 		userId: 'Uc079c5732af5995fa1b36c1292b64b37',
// 		email: 'benzanbenz@gmail.com',
// 		displayName: '<{BenzsaN}>',
// 		pictureUrl: 'https://profile.line-scdn.net/0hwDVa6ZLpKGtrGAAyikdXPFddJgYcNi4jEy0wWBtMdghEK29pX3xiCBxNJFwRfTw7VypiBR4cdV1F'
// }
// localStorage.setItem("IS_LINE_LIFF" , "Y")
// localStorage.setItem("LINE_LIFF_DATA" , JSON.stringify(userData))
// runner()
// }
// else {
// liff.init({ liffId: process.env.VUE_APP_LINE_LIFF_ID })
//     .then(async () => {
//         if (liff.isLoggedIn()) {
//             const profile = await liff.getProfile()
//             const email = liff.getDecodedIDToken().email
//             profile.email = email || ""
//             localStorage.setItem("LINE_LIFF_DATA" , JSON.stringify(profile))
//             runner()
//         } else {
//             if (window.line_liff_origin_path) {
//                 localStorage.setItem("LINE_LIFF_ORIGIN_PATH", window.line_liff_origin_path)
//             }
//             liff.login()
//         }
//     })
//     .catch(err => {
//         console.log(err)
//     })
// }
