import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        sidebar: JSON.parse(localStorage.getItem('sidebar')) || false,
        sidebarMobile: JSON.parse(localStorage.getItem('sidebarMobile')) || false,
    },
    mutations: {
        TOGGLE_SIDEBAR(state, value) {
            state.sidebar = value
            localStorage.setItem('sidebar', value)
        },
        TOGGLE_SIDEBAR_MOBILE(state, value) {
            state.sidebarMobile = value
            localStorage.setItem('sidebarMobile', value)
        },
    },
    actions: {
    },
    modules: {
    }
})
