<template>
    <div class="header header-fixed" :class="{'header-minimize': sidebar}">
        <div class="container-fluid d-flex align-items-stretch justify-content-between">
            <div class="header-menu-wrapper header-menu-wrapper-left"></div>
            <div class="topbar">
                <div class="topbar-item" v-if="!hideLastLogin">
                    <span class="text-muted mr-13">Last Login: {{lastLogin}}</span>
                </div>
                <div class="topbar-item" v-if="showAccountSelect">
                    <button @click="$emit('accountSelect')" type="button" class="btn btn-text-primary btn-hover-light-primary font-size-lg mr-2">{{accountSelectLabel}}</button>
                </div>
                <div class="topbar-item" v-if="!hideLogout">
                    <button @click="$emit('logout')" type="button" class="btn btn-text-danger btn-hover-light-danger font-size-lg logout">Sign out</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: [
        'showBrand',
        'hideBarDetail',
        'hideBrand', 
        'userProfile',
        'hideLastLogin',
        'hideLogout',
        'showAccountSelect',
        'accountSelectLabel',
        'lastLogin']
    ,
    data() {
        return {
        }
    },
    computed: {
        sidebar() {
            return this.$store.state.sidebar;
        },
    },
}
</script>
<style>
.header-minimize {
    left: 70px !important;
}
.topbar-item .logout {
    cursor: pointer;
}
.topbar-item .link-account {
    border-right: 1px solid rgba(0, 0, 0, 0.5);
    cursor: pointer;
}
.topbar-item .link-account:hover {
    color: #27BA08;
}
</style>
