<template>
    <div v-if="!isMobile()" class="aside aside-left aside-fixed d-flex flex-column flex-row-auto" :class="{'aside-minimize': sidebar}" id="kt_aside">
        <div class="brand flex-column-auto" id="kt_brand">
            <div  v-if="!sidebar">
                <a :href="linkOutUrl"  v-if="linkOut">
                    <span class="font-size-lg text-dark-75 mb-1">{{userProfile.display_name}}</span>
                </a>
                <router-link :to="homeLink" class="d-flex align-items-center mr-4" v-else>
                    <!-- <div class="symbol symbol-circle mr-5">
                        <img alt="Pic" :src="userProfile.display_image" @error="replaceByDefault"/>
                    </div> -->
                    <span class="font-size-lg text-dark-75 mb-1">{{userProfile.display_name}}</span>
                </router-link>
            </div>
            <button class="brand-toggle btn btn-sm px-0" @click="toggleSidebar">
                <span class="svg-icon svg-icon svg-icon-xl">
                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px"
                        height="24px" viewBox="0 0 24 24" version="1.1">
                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <polygon points="0 0 24 0 24 24 0 24"/>
                            <path
                                d="M5.29288961,6.70710318 C4.90236532,6.31657888 4.90236532,5.68341391 5.29288961,5.29288961 C5.68341391,4.90236532 6.31657888,4.90236532 6.70710318,5.29288961 L12.7071032,11.2928896 C13.0856821,11.6714686 13.0989277,12.281055 12.7371505,12.675721 L7.23715054,18.675721 C6.86395813,19.08284 6.23139076,19.1103429 5.82427177,18.7371505 C5.41715278,18.3639581 5.38964985,17.7313908 5.76284226,17.3242718 L10.6158586,12.0300721 L5.29288961,6.70710318 Z"
                                fill="#000000" fill-rule="nonzero"
                                transform="translate(8.999997, 11.999999) scale(-1, 1) translate(-8.999997, -11.999999)"/>
                            <path
                                d="M10.7071009,15.7071068 C10.3165766,16.0976311 9.68341162,16.0976311 9.29288733,15.7071068 C8.90236304,15.3165825 8.90236304,14.6834175 9.29288733,14.2928932 L15.2928873,8.29289322 C15.6714663,7.91431428 16.2810527,7.90106866 16.6757187,8.26284586 L22.6757187,13.7628459 C23.0828377,14.1360383 23.1103407,14.7686056 22.7371482,15.1757246 C22.3639558,15.5828436 21.7313885,15.6103465 21.3242695,15.2371541 L16.0300699,10.3841378 L10.7071009,15.7071068 Z"
                                fill="#000000" fill-rule="nonzero" opacity="0.3"
                                transform="translate(15.999997, 11.999999) scale(-1, 1) rotate(-270.000000) translate(-15.999997, -11.999999)"/>
                        </g>
                    </svg>
                </span>
            </button>
        </div>
        <div class="aside-menu-wrapper flex-column-fluid" id="kt_aside_menu_wrapper">
            <div id="kt_aside_menu" class="aside-menu" style="padding-bottom: 184px !important" data-menu-vertical="1" data-menu-scroll="1" data-menu-dropdown-timeout="500">
                <div v-if="showCRMLogo">
                    <img class="my-crm-logo" src="../../assets/logos/my_crm.png" alt="my crm">
                </div>
                <ul class="menu-nav">
                    <nav-group
                        v-for="(menuGroup, index) in menuItems"
                        :key="index"
                        :title="menuGroup.title"
                        :items="menuGroup.items"
                        :currentPage="currentPage"
                        :subMenu="subMenu"
                    />
                </ul>
            </div>
        </div>
    </div>
    <v-navigation-drawer
        v-else
        v-model="drawer"
        absolute
        temporary
    >
        <v-list
            nav
            dense
            class="p-0"
        >
            <div class="aside-menu-wrapper flex-column-fluid">
                <div class="aside-menu" :style="`padding-bottom: ${isMobile() ? '24px' : '152px'} !important`" data-menu-vertical="1" data-menu-scroll="1" data-menu-dropdown-timeout="500">
                    <div class="p-8 pb-0 text-center" style="width: 160px;margin: 0 auto;">
                        <img class="w-100" src="../../assets/logos/yellow_logo_mini.png" alt="">
                    </div>
                    <ul class="menu-nav p-0">
                        <nav-group
                            v-for="(menuGroup, index) in menuItems"
                            :key="index"
                            :title="menuGroup.title"
                            :items="menuGroup.items"
                            :currentPage="currentPage"
                            :subMenu="subMenu"
                        />
                    </ul>
                    <div class="mt-6 px-4">
                        <div class="text-center"  v-if="!hideLastLogin">
                            <span class="text-muted">Last Login: {{lastLogin}}</span>
                        </div>
                        <button v-if="!hideLogout" type="button" class="btn btn-danger btn-block mt-4" @click="$emit('logout')" >Sign Out</button>
                    </div>
                </div>
            </div>
        </v-list>
    </v-navigation-drawer>
</template>

<script>
import NavGroup from './NavGroup'

export default {
    props : [
        "currentPage" , 
        "subMenu", 
        "homeLink", 
        "userProfile", 
        "menuItems", 
        "showCRMLogo",
        'hideLastLogin',
        'hideLogout',
        'linkOut',
        'linkOutUrl',
        'lastLogin'
    ] ,
    components: {
        NavGroup,
    },
    data() {
        return {
            defaultImg: 'https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png',
            drawer: false,
        }
    } ,
    computed : {
        sidebar() {
            return this.$store.state.sidebar
        },
        sidebarMobile() {
            return this.$store.state.sidebarMobile
        },
    } ,
    watch: {
        drawer () {
            this.$store.commit("TOGGLE_SIDEBAR_MOBILE" , this.drawer )
        },
        sidebarMobile() {
            this.drawer = this.sidebarMobile
        }
    },
    mounted() {
        KTLayoutAsideMenu.init("kt_aside_menu")
        this.drawer = this.sidebarMobile
    } ,
    methods : {
        toggleSidebar() {
            this.$store.commit("TOGGLE_SIDEBAR" , !this.sidebar)
        },
        isMobile() {
            if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                return true
            } else {
                return false
            }
        },
        replaceByDefault(event) {
            event.target.src = this.defaultImg
        },
    }
}
</script>

<style>
.aside-minimize {
  width: 70px;
}
.my-crm-logo {
    width: 70%;
    margin: 30px auto -20px;
    display: flex;
}
</style>
