<template>
    <fragment>
        <li class="menu-section">
            <h4 class="menu-text">{{ title }}</h4>
            <i class="menu-icon ki ki-bold-more-hor icon-md"></i>
        </li>
        <nav-item
            v-for="(item, index) in items"
            :key="index"
            :icon="item.icon"
            :label="item.label"
            :to="item.to"
            :outside="item.outside"
            :active="item.label === currentPage"
            :subitems="item.subitems"
            :subMenu="subMenu"
        />
    </fragment>
</template>

<script>
import NavItem from './NavItem'

export default {
    props: ['title', 'items', 'currentPage', 'subMenu'],
    components: {
        NavItem,
    },
}
</script>