import Vue from "vue";
import VueRouter from "vue-router";
import isLoggedIn from './middleware/isLoggedIn';

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: 'index',
        component: () => import('../views/Blank.vue')
    },
    {
        path: "/sign-in",
        name: "Sign In",
        component: () => import("../views/auth/SignIn.vue"),
    },
    {
        path: '/report/user-journey',
        name: 'User Journey report',
        component: () => import('../views/report/UserJourjey.vue'),
        meta: {
            middleware: isLoggedIn,
        },
    },
];

const router = new VueRouter({
    base: process.env.BASE_URL,
    routes,
});

export default router;
