import router from '@/router'

export default function isLoggedIn ({ next, store }){
    const profile = localStorage.getItem("ADMIN_PROFILE_DATA")
    if(!profile){
        window.location.href = "/#/sign-in"
    }
    else {
        return next()
    }
   
}