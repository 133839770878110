<template>
    <div class="header-fixed header-mobile-fixed subheader-fixed aside-enabled aside-fixed aside-minimize-hoverable">
        <div class="d-flex flex-column flex-root">
            <nav-bar
                :currentPage="currentPage"
                :subMenu="subMenu"
                homeLink="/"
                :userProfile="userProfile"
                :menuItems="menuItems()"
                :logout="logout"
            />

            <div class="d-flex flex-column flex-row-fluid wrapper" :class="{ 'content-minimize': sidebar }">
                <div v-if="!isMobile()">
                    <div class="header header-fixed">
                        <div class="container-fluid d-flex align-items-stretch justify-content-between">
                            <div class="header-menu-wrapper header-menu-wrapper-left"></div>
                            <div class="topbar">
                                <div class="topbar-item">
                                    <!-- <span class="text-muted mr-13">Last Login: DD/MM/YYYY HH:mm</span></div> -->
                                    <div class="topbar-item">
                                        <button
                                            type="button"
                                            class="btn btn-text-danger btn-hover-light-danger font-size-lg logout"
                                            @click="logout()"
                                        >
                                            Log out
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- <header-bar
					@logout="logout"
					:userProfile="userProfile"
					v-if="!isMobile()"
				/> -->

                <header-modile :userProfile="userProfile" v-else />
                <div class="card card-custom" style="height: 100%; box-shadow: none">
                    <div class="card-header flex-wrap border-0 pt-3 pb-3 align-items-center">
                        <div class="card-title">
                            <span class="svg-icon mr-2">
                                <slot name="icon" />
                                {{ icon }}
                            </span>
                            <h3 class="card-label">{{ title }}</h3>
                        </div>
                        <div>
                            <slot name="center"></slot>
                        </div>
                        <div>
                            <slot name="right"></slot>
                        </div>
                    </div>
                    <div class="separator separator-solid"></div>
                    <slot />
                    <div
                        v-if="!isMobile()"
                        class="fixed-bottom d-flex align-items-center"
                        :style="`border-top: 1px solid #EAEDF3;background-color: #FFF;width: ${hasButton ? '100%' : '264px'
                        }; z-index: 100`
                        "
                    >
                        <div class="d-flex align-items-center justify-content-center" style="width: 264px">
                            <img class="w-100" src="@/assets/logos/logo-yellow-idea.svg" style="padding: 10px 50px" />
                        </div>
                        <div v-if="hasButton" class="d-flex align-items-center justify-content-start px-16">
                            <slot name="footer"></slot>
                        </div>
                    </div>
                    <div v-else class="d-flex align-items-center justify-content-center py-5" style="background-color: #fff">
                        <slot name="footer"></slot>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { NavBar, HeaderBar, HeaderModile } from "./index";
import queryString from 'querystring'
export default {
    props: ["currentPage", "subMenu", "hasButton", "icon", "title"],
    components: {
        NavBar,
        HeaderBar,
        HeaderModile,
    },
    computed: {
        sidebar() {
            return this.$store.state.sidebar;
        },
    },
    data() {

        return {
            userProfile: {
                mid: "Ua8a8aeeeacd8307888e7df74e9eab83b",
                display_name: "",
                display_image:
                    "https%3A%2F%2Fprofile.line-scdn.net%2F0hMuLc4MS6ElpxGjiRORJtDU1fHDcGNBQSCXsKbwQeRGNfK1JeSnRebAFIHGtVfwIPSikNP1JNTGNa",
            },
            mainMenuAdmin: [
                // {
                //     title: "Main Menu",
                //     items: [
                //     // {
                //     //         icon: `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
				// 	// 																							<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
				// 	// 																									<rect x="0" y="0" width="24" height="24"/>
				// 	// 																									<path d="M18.5,6 C19.3284271,6 20,6.67157288 20,7.5 L20,18.5 C20,19.3284271 19.3284271,20 18.5,20 C17.6715729,20 17,19.3284271 17,18.5 L17,7.5 C17,6.67157288 17.6715729,6 18.5,6 Z M12.5,11 C13.3284271,11 14,11.6715729 14,12.5 L14,18.5 C14,19.3284271 13.3284271,20 12.5,20 C11.6715729,20 11,19.3284271 11,18.5 L11,12.5 C11,11.6715729 11.6715729,11 12.5,11 Z M6.5,15 C7.32842712,15 8,15.6715729 8,16.5 L8,18.5 C8,19.3284271 7.32842712,20 6.5,20 C5.67157288,20 5,19.3284271 5,18.5 L5,16.5 C5,15.6715729 5.67157288,15 6.5,15 Z" fill="#000000"/>
				// 	// 																							</g>
				// 	// 																					</svg>`,
                //     //         label: "Home",
                //     //         to: "/",
                //     //         outside: false,
                //     //     },
                //         {
                //             icon: `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
				// 																								<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
				// 																										<rect x="0" y="0" width="24" height="24"/>
				// 																										<path d="M18.5,6 C19.3284271,6 20,6.67157288 20,7.5 L20,18.5 C20,19.3284271 19.3284271,20 18.5,20 C17.6715729,20 17,19.3284271 17,18.5 L17,7.5 C17,6.67157288 17.6715729,6 18.5,6 Z M12.5,11 C13.3284271,11 14,11.6715729 14,12.5 L14,18.5 C14,19.3284271 13.3284271,20 12.5,20 C11.6715729,20 11,19.3284271 11,18.5 L11,12.5 C11,11.6715729 11.6715729,11 12.5,11 Z M6.5,15 C7.32842712,15 8,15.6715729 8,16.5 L8,18.5 C8,19.3284271 7.32842712,20 6.5,20 C5.67157288,20 5,19.3284271 5,18.5 L5,16.5 C5,15.6715729 5.67157288,15 6.5,15 Z" fill="#000000"/>
				// 																								</g>
				// 																						</svg>`,
                //             label: "Medical Registration",
                //             to: "/medical-register",
                //             outside: false,
                //         },
                //         {
                //             icon: `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
				// 																								<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
				// 																										<rect x="0" y="0" width="24" height="24"/>
				// 																										<path d="M18.5,6 C19.3284271,6 20,6.67157288 20,7.5 L20,18.5 C20,19.3284271 19.3284271,20 18.5,20 C17.6715729,20 17,19.3284271 17,18.5 L17,7.5 C17,6.67157288 17.6715729,6 18.5,6 Z M12.5,11 C13.3284271,11 14,11.6715729 14,12.5 L14,18.5 C14,19.3284271 13.3284271,20 12.5,20 C11.6715729,20 11,19.3284271 11,18.5 L11,12.5 C11,11.6715729 11.6715729,11 12.5,11 Z M6.5,15 C7.32842712,15 8,15.6715729 8,16.5 L8,18.5 C8,19.3284271 7.32842712,20 6.5,20 C5.67157288,20 5,19.3284271 5,18.5 L5,16.5 C5,15.6715729 5.67157288,15 6.5,15 Z" fill="#000000"/>
				// 																								</g>
				// 																						</svg>`,
                //             label: "Manage Content",
                //             to: "/content",
                //             outside: false,
                //         },

                //     ],
                // },

                {
                    title: "Report",
                    items: [
                        {
                            icon: `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
																												<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
																														<rect x="0" y="0" width="24" height="24"/>
																														<path d="M18.5,6 C19.3284271,6 20,6.67157288 20,7.5 L20,18.5 C20,19.3284271 19.3284271,20 18.5,20 C17.6715729,20 17,19.3284271 17,18.5 L17,7.5 C17,6.67157288 17.6715729,6 18.5,6 Z M12.5,11 C13.3284271,11 14,11.6715729 14,12.5 L14,18.5 C14,19.3284271 13.3284271,20 12.5,20 C11.6715729,20 11,19.3284271 11,18.5 L11,12.5 C11,11.6715729 11.6715729,11 12.5,11 Z M6.5,15 C7.32842712,15 8,15.6715729 8,16.5 L8,18.5 C8,19.3284271 7.32842712,20 6.5,20 C5.67157288,20 5,19.3284271 5,18.5 L5,16.5 C5,15.6715729 5.67157288,15 6.5,15 Z" fill="#000000"/>
																												</g>
																										</svg>`,
                            label: "User Journey Report",
                            to: "/report/user-journey",
                            outside: false,
                        },
                    ],
                },

                // Report Virtual Session
            ],

        };
    },
    mounted() {
        const profile = localStorage.getItem("ADMIN_PROFILE_DATA");

        const { code, state } = queryString.parse(window.location.search.replace('?', ''))

        if (code) {

        };
        if (profile === null && code === undefined) {
            this.$router.push("/sign-in");
        } else {

        }
    },
    methods: {
        isMobile() {
            if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                return true;
            } else {
                return false;
            }
        },
        menuItems() {
            let menu = [];
            menu = this.mainMenuAdmin;
            // if (this.role === "1" || this.role === "0") {
            //     menu = this.mainMenuAdmin;
            // } else if (this.role === "2") {
            //     menu = this.mainMenuOperater;
            // } else {
            //     menu = this.mainMenuOther;
            // }
            return menu;
        },
        logout() {
            localStorage.removeItem("ADMIN_PROFILE_DATA");
            // this.$router.push("/#/sign-in");
            window.location.href = "/#/sign-in"
        },
    },
};
</script>

<style scoped>
.content-minimize {
    padding-left: 70px !important;
}

.svg-icon {
    color: #00c300;
}
</style>
