<template>
    <v-app>
        <router-view />
    </v-app>
</template>

<script>

export default {
    data() {
        return {
            loading: false,
            token: [],
        };
    },
    methods: {
    },
};
</script>

<style lang="scss">
</style>
