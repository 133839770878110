<template>
    <div class="header-mobile-wrap">
        <div class="header">
            <div>
                <button type="button" @click.stop="toggleSidebarMobile">
                    <i class="la la-navicon icon-3x"></i>
                </button>
            </div>
            <div>
                <router-link to="/loyalty/home" class="d-flex align-items-center">
                    <div class="d-flex flex-column text-right mr-5">
                        <span class="font-size-lg text-dark-75 mb-1">{{userProfile.display_name}}</span>
                    </div>
                    <div class="symbol symbol-circle symbol-40">
                        <img @error="replaceByDefault" alt="Pic" :src="userProfile.display_image ? userProfile.display_image : defaultImg"/>
                    </div>
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['userProfile'],
    data() {
        return {
            defaultImg: 'https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png',
        }
    },
    methods: {
        toggleSidebarMobile() {
            this.$store.commit("TOGGLE_SIDEBAR_MOBILE" , true)
        },
        replaceByDefault(event) {
            event.target.src = this.defaultImg
        },
    },
}
</script>
<style scoped>
.header-mobile-wrap {
    box-shadow: 3px 2px 4px rgba(0, 0, 0, 0.2) !important;
    height: 60px;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1;
    background-color: #FFF;
}
.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    padding: 0 12px;
}
</style>
